export const ARTICLE_FILTER = 'eversys/article/filter';
export const CAREERS_FILTER = 'eversys/careers/filter';
export const SET_COUNTRY = 'eversys/country/set';
export const SET_ALL_COUNTRY = 'eversys/countryList/set';

export const setArticleFilter = filters => ({
  type: ARTICLE_FILTER,
  payload: {
    filters,
  }
});

export const setCareersFilter = filters => ({
  type: CAREERS_FILTER,
  payload: {
    filters,
  }
});

export const setCountry = country => ({
  type: SET_COUNTRY,
  payload: {
    country,
  }
});

export const setCountryList = countryList => ({
  type: SET_ALL_COUNTRY,
  payload: {
    countryList,
  }
});
