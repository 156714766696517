import thunk from 'redux-thunk'
import {
  createStore as reduxCreateStore,
  applyMiddleware,
  compose,
} from 'redux'
import { ARTICLE_FILTER, SET_COUNTRY, SET_ALL_COUNTRY, CAREERS_FILTER } from './actions'

const reducer = (state, action) => {
  if (action.type === ARTICLE_FILTER) {
    return {
      ...state,
      articleFilter: action.payload.filters,
    }
  } else if (action.type === SET_COUNTRY) {
    return {
      ...state,
      country: action.payload.country,
    }
  } else if (action.type === SET_ALL_COUNTRY) {
    return {
      ...state,
      countryList: action.payload.countryList,
    }
  } else if (action.type === CAREERS_FILTER) {
    return {
		...state,
		careersFilter: action.payload.filters,
    }
  }

  return state
}

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose
const initialState = {
  articleFilter: [],
  careersFilter: [],
  countryList: [],
  country: {
    country: {
      text: 'United Kingdom',
    },
    country_code: {
      text: 'GB',
    },
    redirect_to: {
      url: 'https://dev.eversys.nrthpoint.com/en',
    },
    language: {
      text: 'EN',
    },
    contact_email: {
      text: 'martin@eversys.ch',
    },
  },
}

const createStore = () =>
  reduxCreateStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  )

export default createStore
